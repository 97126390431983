module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-wiki-link","options":{"stripBrackets":false,"stripDefinitionExts":[".md",".mdx"]}},"gatsby-remark-double-parenthesis-link",{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":["md","mdx"]}},{"resolve":"gatsby-remark-prismjs","options":{"noInlineHighlight":true}}],"rehypePlugins":[null],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0/_layouts","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-tocbot/gatsby-browser.js'),
      options: {"plugins":[],"tocbotOptions":{"contentSelector":".topic-layout__content","collapseDepth":5,"scrollContainer":".topic-layout__content"}},
    },{
      plugin: require('../node_modules/gatsby-theme-kb/gatsby-browser.js'),
      options: {"plugins":[],"rootNote":"/readme","contentPath":"/vercel/path0/_layouts/..","ignore":["**/_layouts/**","**/.git/**","**/.github/**","**/.vscode/**","**/.cache/**","**/_site/**","**/_remote_site/**"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
